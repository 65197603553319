import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Section/Section";
import { HeaderUnderline } from "../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import { Container, Grid } from "@material-ui/core";
import { useTransparentAccountImage } from "./hooks/useTransparentAccountImage";
import GatsbyImage from "gatsby-image";
import { useMemo } from "react";
import { ServiceListItem } from "../../sluzby/components/ServiceListItem/ServiceListItem";

export interface TransparentAccountSectionProps {
  className?: string;
}

const ImageVisualComponent = styled.div`
  height: 135px;
  width: 15px;
  background: ${props => props.theme.color.primary};
`;

const ImageVisualComponentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PointsList = styled.div`
  margin-top: 30px;
`;

const TransparentAccountSectionInner = (
  props: TransparentAccountSectionProps
) => {
  const { t } = useTranslation("home");
  const image = useTransparentAccountImage();
  const points = useMemo(
    () =>
      Object.values(
        t("transparentAccount.points", {
          returnObjects: true
        })
      ),
    []
  );

  // This one is ugly AF but created close to a deadline as well....
  return (
    <Section background className={props.className}>
      <Container>
        <HeaderUnderline align="center">
          {t("transparentAccount.title")}
        </HeaderUnderline>
        <Grid container spacing={4}>
          <Grid
            container
            item
            xs={12}
            sm={4}
            style={{
              flexWrap: "nowrap"
            }}
          >
            <GatsbyImage style={{ width: "100%" }} fluid={image} />
            <ImageVisualComponentWrapper>
              <ImageVisualComponent />
            </ImageVisualComponentWrapper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <h2>{t("transparentAccount.subtitle")}</h2>
            <p>{t("transparentAccount.text")}</p>
            <PointsList>
              {points.map((point: any) => (
                <ServiceListItem key={point.text} {...point} />
              ))}
            </PointsList>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const TransparentAccountSection = styled(TransparentAccountSectionInner)`
  position: relative;
  padding: 30px 0;
}
`;
