import React from "react";
import styled from "styled-components";
import { HeaderUnderline } from "../../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import { usePartnerImages } from "../hooks/usePartnerImages";
import { Grid } from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import { muiTheme } from "../../../../lib/Theme";

export interface PartnersSectionProps {
  className?: string;
}

const ParnerImage = styled(GatsbyImage)`
  min-width: 180px;
  display: flex;
  flex: 1;
  max-width: 400px;
  ${muiTheme.breakpoints.down("sm")} {
    max-width: 250px;
    margin: 20px 0;
  }
`;

const PartnersSectionInner = (props: PartnersSectionProps) => {
  const { t } = useTranslation("home");
  const partnerImages = usePartnerImages();

  return (
    <article className={props.className}>
      <HeaderUnderline>{t("ourPartners.title")}</HeaderUnderline>
      <Grid
        container
        style={{
          padding: "20px 0"
        }}
        spacing={3}
      >
        {partnerImages.map((image, index) => (
          <Grid
            key={index}
            item
            xs
            sm={index === 6 ? 12 : 0}
            container
            justify="center"
            alignItems="center"
          >
            <ParnerImage
              imgStyle={{
                objectFit: "contain",
                objectPosition: "50% 50%"
              }}
              style={{
                minWidth: 180,
                display: "flex",
                flex: 1
              }}
              fluid={image}
            />
          </Grid>
        ))}
      </Grid>
    </article>
  );
};

export const PartnersSection = styled(PartnersSectionInner)``;
