import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Section/Section";
import { Container, Grid } from "@material-ui/core";
import { PartnersSection } from "./components/Parterns";
import { ReferencesSection } from "./components/References";
import { muiTheme } from "../../../lib/Theme";

export interface PartnersAndReferencesProps {
  className?: string;
}

const PartnersAndReferencesInner = (props: PartnersAndReferencesProps) => {
  return (
    <Section className={props.className}>
      <Container>
        <Grid container spacing={6}>
          <Grid item md={6} xs={12}>
            <PartnersSection />
          </Grid>
          <Grid item md={6} xs={12}>
            <ReferencesSection />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const PartnersAndReferences = styled(PartnersAndReferencesInner)`
  overflow: hidden;
  margin-top: 200px;

  ${muiTheme.breakpoints.down("sm")} {
    margin-top: ${props => props.theme.spacing.sectionMarginTop};
  }
`;
