import React from "react";
import styled from "styled-components";
import SvgChat from "../../../../assets/icons/Chat";
import Skusenosti from "./../icons/transparentnost.svg";

export interface WhyChooseUsPerkProps {
  title: string;
  text: string;
  icon: any;
  className?: string;
}

const WhyChooseUsPerkInner = (props: WhyChooseUsPerkProps) => {
  const { title, text, icon } = props;
  return (
    <article className={props.className}>
      <h3>{title}</h3>
      <div className="whuchoooseusperk__bottom">
        <div className="whuchoooseusperk__icon">
          <img
            style={{
              height: 350,
              width: 350
            }}
            src={icon}
            alt="logo"
          />
        </div>
        <p>{text}</p>
      </div>
    </article>
  );
};

export const WhyChooseUsPerk = styled(WhyChooseUsPerkInner)`
  display: flex;
  flex-flow: column;

  h3 {
    font-weight: 600;
    margin: 0;
    color: black;
  }

  .whuchoooseusperk {
    &__bottom {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
    &__icon {
      display: flex;
      height: 80px;
      min-width: 80px;
      background: ${props => props.theme.color.primary};
      margin-right: 20px;
      color: white;
      justify-content: center;
      align-items: center;
    }
  }
  p {
    color: ${props => props.theme.color.gray};
  }
`;
