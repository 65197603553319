import { FluidObject } from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export const useTransparentAccountImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query TransparentAccountImage {
      file(
        relativePath: {
          eq: "images/home/transparent-account/transparent-account.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 89, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return file.childImageSharp.fluid;
};
