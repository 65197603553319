import React from "react";
import styled from "styled-components";
import { Container, Grid } from "@material-ui/core";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { HeaderUnderline } from "../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import {
  WhyChooseUsPerk,
  WhyChooseUsPerkProps
} from "./components/WhyChooseUsPerk";
import { Section } from "../../Shared/Section/Section";
import Skusenosti from "./icons/Skusenosti.svg";
import Transparentost from "./icons/transparentnost.svg";
import Spolahlivost from "./icons/spolahlivost.svg";

export interface WhyChooseUsProps {
  className?: string;
}

const WhyChooseUsSubheader = styled.p`
  margin-bottom: 40px;
  text-align: center;
`;

const perks = [
  {
    title: "Skúsenosti",
    icon: Skusenosti,
    text:
      "Stojíme na pevných základoch, zodpovednosti a korektnosti. Proklientský prístup je u nás samozrejmosťou."
  },
  {
    title: "Transparentnosť",
    icon: Transparentost,
    text:
      "ALTRO ponúka pružnú komunikáciu so správcom, skoré vyplácanie preplatkov, nestranný výber dodávateľských firiem."
  },
  {
    title: "Spoľahlivosť",
    icon: Spolahlivost,
    text:
      "Veríme, že dôvera je základom kvalitnej správy nehnuteľností, vždy stojíme na vašej strane. Na čom sa dohodneme, to splníme."
  }
];

const WhyChooseUsInner = (props: WhyChooseUsProps) => {
  const { t } = useTranslation("home");
  return (
    <Section className={props.className}>
      <Container>
        <HeaderUnderline align="center">
          {t("whyChooseUs.title")}
        </HeaderUnderline>
        <WhyChooseUsSubheader>{t("whyChooseUs.subtitle")}</WhyChooseUsSubheader>
        <Grid container spacing={5}>
          {perks.map(perk => (
            <Grid item xs={12} sm={4} key={perk.title}>
              <WhyChooseUsPerk
                title={perk.title}
                text={perk.text}
                icon={perk.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const WhyChooseUs = styled(WhyChooseUsInner)``;
