import { useStaticQuery, graphql } from "gatsby";

export const usePartnerImages = () => {
  const { images } = useStaticQuery(graphql`
    query PartnerImages {
      images: allFile(
        filter: { relativeDirectory: { eq: "images/home/partners" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);
  return images.nodes.map((image: any) => image.childImageSharp.fluid);
};
