import React from "react";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";
import { Test } from "../components/Shared/Test/Test";
import { HeroSection } from "../components/home/HeroSection/HeroSection";
import { WhyChooseUs } from "../components/home/WhyChooseUs/WhyChooseUs";
import { TransparentAccountSection } from "../components/home/TransparentAccountSection/TransparentAccountSection";
import { AssistService } from "../components/home/AssistService/AssistService";
import { PartnersAndReferences } from "../components/home/PartnersAndReferences/PartnersAndReferences";
import { ContactFormSection } from "../components/Shared/ContactFormSection/ContactFormSection";
import { DocumentsLinkSection } from "../components/home/DocumentsLinkSection/DocumentsLinkSection";

const IndexPage = () => (
  <Layout noHeader>
    <SEO description="This is an index page" />
    <HeroSection />
    <WhyChooseUs />
    <TransparentAccountSection />
    <AssistService />
    <PartnersAndReferences />
    <DocumentsLinkSection />
    <ContactFormSection />
  </Layout>
);

export default IndexPage;
