import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { HeaderUnderline } from "../../../Shared/HeaderUnderline/HeaderUnderline";
import GatsbyImage from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export interface ReferencesSectionProps {
  className?: string;
}

const Reference = styled.div``;

const ReferenceBody = styled.p`
  background: ${props => props.theme.color.sectionBg};
  margin-top: 20px;
  padding: 30px;
`;

const ReferenceTitle = styled.h4`
  margin-bottom: 7px;
`;

const ReferenceText = styled.p`
  color: ${props => props.theme.color.gray};
`;

const ReferencesSectionInner = (props: ReferencesSectionProps) => {
  const { t } = useTranslation("home");
  const documents = t("documents", { returnObjects: true });
  const { file } = useStaticQuery(graphql`
    query ReferenceImage {
      file(
        absolutePath: {}
        relativePath: { eq: "images/home/references/reference.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <article className={props.className}>
      <HeaderUnderline>{t("documents.title")}</HeaderUnderline>
      <Reference>
        <GatsbyImage
          imgStyle={{
            height: 200
          }}
          className="g-image"
          fluid={file.childImageSharp.fluid}
        />
        <ReferenceBody>
          <ReferenceTitle>{documents.subtitle}</ReferenceTitle>
          <ReferenceText>{documents.text}</ReferenceText>
        </ReferenceBody>
      </Reference>
    </article>
  );
};

export const ReferencesSection = styled(ReferencesSectionInner)`
  .g-image {
    height: 200px;
    &::before,
    &::after {
      height: 200px;
    }
  }
`;
