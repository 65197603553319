import React from "react";
import styled from "styled-components";

export interface AssistServiceItemProps {
  title: string;
  name: string;
  contact: string;
  className?: string;
}

const AssistServiceItemInner = (props: AssistServiceItemProps) => {
  const { title, name, contact } = props;
  return (
    <div className={props.className}>
      <h3>{title}</h3>
      <a href={`tel:${contact}`}>
        <b>{name}</b>: {contact}
      </a>
    </div>
  );
};

export const AssistServiceItem = styled(AssistServiceItemInner)`
  margin-bottom: 20px;
  h4 {
    margin-bottom: 4px;
    color: black;
  }

  b {
    font-weight: 600;
  }

  p {
    margin: 0;
  }
`;
