import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Section/Section";
import { Container, Hidden } from "@material-ui/core";
import { HeaderUnderline } from "../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import { useAssistServiceImage } from "./hooks/useAssistServiceImage";
import GatsbyImage from "gatsby-image";
import { muiTheme } from "../../../lib/Theme";
import { useMemo } from "react";
import {
  AssistServiceItem,
  AssistServiceItemProps
} from "./components/AssisServiceItem";

export interface AssistServiceProps {
  className?: string;
}

const AssistServicesContact = styled.div`
  margin-top: 30px;
`;

const AssistServiceInner = (props: AssistServiceProps) => {
  const { t } = useTranslation("home");
  const image = useAssistServiceImage();

  const services: AssistServiceItemProps[] = useMemo(
    () => t("assistService.items", { returnObjects: true }),
    []
  );
  return (
    <Section className={props.className}>
      <Container>
        <HeaderUnderline>{t("assistService.title")}</HeaderUnderline>
        <div className="text">
          <h2>{t("assistService.subheader.title")}</h2>
          <p
            style={{
              width: "85%"
            }}
          >
            {t("assistService.subheader.text")}
          </p>
          <AssistServicesContact>
            {services.map(serviceItem => (
              <AssistServiceItem key={serviceItem.title} {...serviceItem} />
            ))}
          </AssistServicesContact>
        </div>
      </Container>
      <Hidden mdDown>
        <div className="assist__image-wrapper">
          <GatsbyImage fluid={image} />
        </div>
      </Hidden>
    </Section>
  );
};

export const AssistService = styled(AssistServiceInner)`
  position: relative;
  .text {
    max-width: 90%;
  }
  .assist__image-wrapper {
    position: absolute;
    top: -40px;
    right: 0;
    width: 450px;
    z-index: -1;
  }
  p {
    margin-bottom: 10px;
  }
`;
